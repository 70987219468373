import React from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import SEO from "../components/seo"
import { Footer } from "../components/nav"
import { SliceZone } from "../components/slices"

const IS_BROWSER = typeof window !== "undefined"

const IndexPage = ({ data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const mergedData = mergePrismicPreviewData({ staticData, previewData })
  const {
    page_title,
    meta_description,
    keywords,
    body,
  } = mergedData.prismicPage.data

  const keywordArray = keywords ? keywords.split(",") : []
  return (
    <>
      <SEO
        title={page_title.text}
        description={meta_description}
        keywords={keywordArray}
      />
      {body && <SliceZone slices={body} />}
      <Footer />
    </>
  )
}

export const PageQuery = graphql`
  {
    prismicPage(tags: { eq: "Homepage" }) {
      data {
        page_title {
          text
        }
        meta_description
        keywords
        body {
          ... on PrismicPageBodyPageBanner {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                thumbnails {
                  Mobile {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              heading {
                text
              }
              text {
                html
              }
              size
            }
          }
          ... on PrismicPageBodyPageSpacer {
            id
            slice_type
            primary {
              size
            }
          }
          ... on PrismicPageBodyPageIntro {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyTextBlock {
            id
            slice_type
            primary {
              subheading {
                text
              }
              text {
                html
              }
              link {
                url
              }
              link_text
              include_in_page_nav
              nav_title
            }
          }
          ... on PrismicPageBodyFeatureText {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              link {
                url
              }
              link_text
              include_in_page_nav
              nav_title
            }
          }
          ... on PrismicPageBodyCardCarousel {
            id
            slice_type
            primary {
              heading {
                text
              }
              include_in_page_nav
              nav_title
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 860, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heading {
                text
              }
              text {
                text
                html
              }
              page {
                url
              }
            }
          }
          ... on PrismicPageBodyVideoCarousel {
            id
            slice_type
            primary {
              heading {
                text
              }
              include_in_page_nav
              nav_title
            }
            items {
              video_poster {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 860, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video {
                url
              }
              video_title {
                text
              }
            }
          }
          ... on PrismicPageBodyModalForm {
            id
            slice_type
            primary {
              form_name
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyPartnersCarousel {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              include_in_page_nav
              nav_title
            }
            items {
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              website {
                url
              }
            }
          }
          ... on PrismicPageBodyTwoColumnText {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              include_in_page_nav
              nav_title
            }
          }
          ... on PrismicPageBodyImageText {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                html
              }
              link {
                url
              }
              link_text
              include_in_page_nav
              nav_title
              reverse_layout
            }
          }
          ... on PrismicPageBodyRegion {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              region
              text {
                html
              }
              form_text {
                html
              }
              reverse_layout
              include_in_page_nav
              nav_title
            }
          }
          ... on PrismicPageBodyLogoGrid {
            id
            slice_type
            items {
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              website {
                url
              }
            }
          }
          ... on PrismicPageBodyContactForm {
            id
            slice_type
          }
        }
      }
    }
  }
`

export default IndexPage
